var columns = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'username',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'typemsg',
  key: 'typemsg',
  scopedSlots: {
    customRender: 'typemsg'
  }
}, {
  title: '等级',
  dataIndex: 'levelname',
  key: 'levelname',
  scopedSlots: {
    customRender: 'levelname'
  }
}, {
  // title: '邮箱',
  dataIndex: 'email',
  key: 'email',
  // width: '10%',
  scopedSlots: {
    customRender: 'email'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '手机号',
  dataIndex: 'phone',
  key: 'phone',
  // width: '10%',
  scopedSlots: {
    customRender: 'phone'
  }
}, {
  title: '退货地址',
  dataIndex: 'addr',
  key: 'addr',
  // width: '5%',
  scopedSlots: {
    customRender: 'addr'
  }
}, {
  title: '邮编',
  dataIndex: 'zipcode',
  key: 'zipcode',
  // width: '5%',
  scopedSlots: {
    customRender: 'zipcode'
  }
}, {
  title: '收款账户',
  dataIndex: 'bankno',
  key: 'bankno',
  // width: '5%',
  scopedSlots: {
    customRender: 'bankno'
  }
}, {
  title: '首次出售时间',
  dataIndex: 'firstselltime',
  key: 'firstselltime',
  // width: '5%',
  scopedSlots: {
    customRender: 'firstselltime'
  }
}, {
  title: '限量发售',
  dataIndex: 'limitcount',
  key: 'limitcount',
  fixed: "right",
  width: '130',
  scopedSlots: {
    customRender: 'limitcount'
  }
}, {
  title: '在售商品',
  dataIndex: 'salecount',
  key: 'salecount',
  width: '130',
  fixed: 'right',
  scopedSlots: {
    customRender: 'salecount'
  }
}, {
  title: '保证金余额',
  dataIndex: 'bond',
  key: 'bond',
  fixed: "right",
  width: '130',
  scopedSlots: {
    customRender: 'bond'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var addColumns = [{
  title: '商品ID',
  dataIndex: 'productid',
  key: 'productid',
  // width: '8%',
  scopedSlots: {
    customRender: 'productid'
  }
}, {
  title: '商品类目',
  dataIndex: 'managementcategory',
  key: 'managementcategory',
  // width: '19%',
  scopedSlots: {
    customRender: 'managementcategory'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandidmsg',
  key: 'brandidmsg',
  // width: '8%',
  scopedSlots: {
    customRender: 'brandidmsg'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '13%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'image',
  key: 'image',
  // width: '9%',
  scopedSlots: {
    customRender: 'image'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  // width: '25%',
  scopedSlots: {
    customRender: 'name'
  },
  ellipsis: true
}, {
  title: '尺码',
  dataIndex: 'size',
  key: 'size',
  // width: '25%',
  scopedSlots: {
    customRender: 'size'
  },
  ellipsis: true
}, {
  title: '当前售价($)',
  dataIndex: 'price',
  key: 'price',
  // width: '20%',
  scopedSlots: {
    customRender: 'price'
  }
}, {
  title: '保证金($)/件',
  dataIndex: 'bond',
  key: 'bond',
  // width: '20%',
  scopedSlots: {
    customRender: 'bond'
  }
}, {
  title: '数量',
  dataIndex: 'count',
  key: 'count',
  // width: '20%',
  scopedSlots: {
    customRender: 'count'
  }
}, {
  title: '售价总额($)',
  dataIndex: 'totalCount',
  key: 'totalCount',
  // width: '20%',
  scopedSlots: {
    customRender: 'totalCount'
  }
}, {
  title: '保证金总额',
  dataIndex: 'totalBond',
  key: 'totalBond',
  // width: '20%',
  scopedSlots: {
    customRender: 'totalBond'
  }
}
// {
//     title: '操作',
//     dataIndex: 'action',
//     key: 'action',
//     // width: '8%',
//     scopedSlots: {
//         customRender: 'action'
//     }
// },
];

var depositColumns = [{
  title: 'UID',
  dataIndex: 'userid',
  key: 'userid',
  scopedSlots: {
    customRender: 'userid'
  }
}, {
  title: '商家名称',
  dataIndex: 'userName',
  key: 'userName',
  // width: '7%',
  scopedSlots: {
    customRender: 'userName'
  }
}, {
  title: '商家类型',
  dataIndex: 'userTypeName',
  key: 'userTypeName',
  // width: '5%',
  scopedSlots: {
    customRender: 'userTypeName'
  }
}, {
  // title: '邮箱',
  dataIndex: 'userEmail',
  key: 'userEmail',
  // width: '10%',
  scopedSlots: {
    customRender: 'userEmail'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '商品',
  dataIndex: 'url',
  key: 'url',
  // width: 300,
  scopedSlots: {
    customRender: 'url'
  }
}, {
  title: '事项',
  dataIndex: 'typeName',
  key: 'typeName',
  // width: '10%',
  scopedSlots: {
    customRender: 'typeName'
  }
}, {
  title: '保证金($)',
  dataIndex: 'bond',
  key: 'bond',
  // width: '10%',
  scopedSlots: {
    customRender: 'bond'
  }
}, {
  title: '收单费($)',
  dataIndex: 'receipt',
  key: 'receipt',
  // width: '10%',
  scopedSlots: {
    customRender: 'receipt'
  }
}, {
  title: '支付金额($)',
  dataIndex: 'amount',
  key: 'amount',
  // width: '10%',
  scopedSlots: {
    customRender: 'amount'
  }
}, {
  title: '保证金余额($)',
  dataIndex: 'availablebond',
  key: 'availablebond',
  // width: '10%',
  scopedSlots: {
    customRender: 'availablebond'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '10%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns, addColumns, depositColumns };