var detailColumns = [{
  title: '',
  dataIndex: 'all',
  key: 'all',
  // width: '12%',
  scopedSlots: {
    customRender: 'all'
  }
}, {
  title: '时间',
  dataIndex: 'month',
  key: 'month',
  // width: '12%',
  scopedSlots: {
    customRender: 'month'
  }
}, {
  title: '商家类型',
  dataIndex: 'userTypeName',
  key: 'userTypeName',
  // width: '10%',
  scopedSlots: {
    customRender: 'userTypeName'
  }
}, {
  title: '商家等级',
  dataIndex: 'levelName',
  key: 'levelName',
  // width: '10%',
  scopedSlots: {
    customRender: 'levelName'
  }
}, {
  title: '折扣',
  dataIndex: 'discountrate',
  key: 'discountrate',
  // width: '10%',
  scopedSlots: {
    customRender: 'discountrate'
  }
}, {
  title: '鉴定通过订单数',
  dataIndex: 'checkpassnum',
  key: 'checkpassnum',
  // width: '10%',
  scopedSlots: {
    customRender: 'checkpassnum'
  }
}, {
  title: '销售额($)',
  dataIndex: 'salestotal',
  key: 'salestotal',
  // width: '10%',
  scopedSlots: {
    customRender: 'salestotal'
  }
}, {
  title: '未履约订单数',
  dataIndex: 'unperformancenum',
  key: 'unperformancenum',
  // width: '10%',
  scopedSlots: {
    customRender: 'unperformancenum'
  }
}, {
  title: '履约率',
  dataIndex: 'performancerate',
  key: 'performancerate',
  // width: '10%',
  scopedSlots: {
    customRender: 'performancerate'
  }
}];
export { detailColumns };