var searchKeys = [{
  key: 'dates',
  label: '首次出售时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "type",
  label: "商家类型",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "level",
  label: "等级",
  placeholder: "请先选择商家类型",
  required: false,
  rules: [],
  select: true,
  disabled: true
}, {
  key: "name",
  label: "商家名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "email",
  label: "邮箱",
  placeholder: "邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var saleKeys = [{
  key: 'email',
  label: '商家',
  placeholder: "请输入邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "parentcategoryid",
  label: "类目",
  placeholder: "请选择类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "categoryid",
  label: "",
  placeholder: "二级类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "productid",
  label: "商品ID",
  placeholder: "请输入",
  required: false,
  rules: [{
    pattern: /^\+?[1-9]\d*$/,
    message: '请输入正确ID',
    trigger: ['change']
  }],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var depositKeys = [{
  key: 'dates',
  label: '时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "dataNo",
  label: "订单号",
  placeholder: "订单号",
  required: false,
  rules: [],
  input: true
}, {
  key: "type",
  label: "商家类型",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "user",
  label: "商家",
  placeholder: "名称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "全部",
  required: false,
  rules: [],
  input: true
}, {
  key: "userId",
  label: "UID",
  placeholder: "UID",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, saleKeys, depositKeys };